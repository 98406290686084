import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <p>
                <b>Address: </b>Laois Cricket Club, The Abbey, Stradbally, Co. Laois <br></br>
                <b>Email: </b><a href = "mailto: lornabouclier66@gmail.com"> lornabouclier66@gmail.com</a> <br></br>
                <b>Phone: </b> 086 190 8073 <br></br>
                <b>Primary Contact: </b> Adrian Tudor (Teams Manager / Fixture Secretary) - <a href = "mailto: aditudor11@yahoo.co.uk"> aditudor11@yahoo.co.uk </a> | 086 190 8073 <br></br>
              </p>
            </div>
          </div>
          <hr></hr>
          <div className="container">
            <div class="mapouter">
              <div class="google-maps">
                <iframe width="600" height="450" id="google-maps" src="https://maps.google.com/maps?q=laois%20cricket&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="5" scrolling="no" allowFullScreen="" loading="lazy"></iframe>
                <br></br>
                </div>
                </div>
          </div>
        </section>
      </Layout>
    );
  }
}
